import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [haiku, setHaiku] = useState([]);

  const fetchHaiku = async (word) => {
    setHaiku("");

    const response = await axios.post(
      "/.netlify/functions/open",
      {
        word,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { haikuText } = response.data;

    const haikuLines = haikuText.split("\n");

    setHaiku(haikuLines);
  };

  useEffect(() => {
    fetchHaiku();
  }, []);

  let counter = 0;

  return (
    <div className="haiku-container">
      {haiku && (
        <div className="haiku">
          {haiku.map((line) => {
            return (
              <p key={line} className="haiku-line">
                {line.split(" ").map((word) => {
                  counter++;
                  return (
                    <span
                      key={word}
                      className="haiku-word"
                      onClick={() => fetchHaiku(word)}
                      style={{
                        animationDelay: `${counter * 0.15}s`,
                      }}
                    >
                      {word}{" "}
                    </span>
                  );
                })}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default App;
